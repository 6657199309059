import { DragEvent } from 'react';

type Props = {
    hookDragEnter?: (e: DragEvent) => void;
    hookDragOver?: (e: DragEvent) => void;
    hookDragLeave?: (e: DragEvent) => void;
};

export type GetGuardProps = () => {
    onDragEnter: (e: DragEvent) => void;
    onDragOver: (e: DragEvent) => void;
    onDragLeave: (e: DragEvent) => void;
};

export const useDragFileGuard = (props: Props) => {
    const { hookDragEnter, hookDragOver, hookDragLeave } = props;

    const noEffect = (e: DragEvent) => {
        e.preventDefault();
        if (e.dataTransfer) {
            e.dataTransfer.effectAllowed = 'none';
            e.dataTransfer.dropEffect = 'none';
        }
    };

    // getGuardProps関数をpropsに設定したコンポーネント上にDnDを試みると無効化されます
    //（これがないと、別タブに画像等のファイルの内容が表示されてしまう）
    const getGuardProps: GetGuardProps = () => {
        return {
            onDragEnter: (e: DragEvent) => {
                noEffect(e);
                if (hookDragEnter) {
                    hookDragEnter(e);
                }
            },
            onDragOver: (e: DragEvent) => {
                noEffect(e);
                if (hookDragOver) {
                    hookDragOver(e);
                }
            },
            onDragLeave: (e: DragEvent) => {
                noEffect(e);
                if (hookDragLeave) {
                    hookDragLeave(e);
                }
            },
        };
    };

    return {
        getGuardProps,
    };
};
