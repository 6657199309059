import { useCallback, useEffect, useRef } from 'react';
import { useOnEnterCallback } from '@view-model/models/common/hooks/useOnEnterCallback';
import { classNames } from '@framework/utils';

type Props = {
    value: string;
    onBlur(e: React.FocusEvent<HTMLInputElement>): void;
    onClick(): void;
    isEditable: boolean;
    isEditing: boolean;
    ariaLabel: {
        input: string;
        display: string;
    };
};

export const TimeDisplay: React.FC<Props> = ({ value, onBlur, isEditing, isEditable, onClick, ariaLabel }: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useOnEnterCallback(inputRef, false);

    useEffect(() => {
        if (inputRef.current && !inputRef.current.matches(':focus')) {
            inputRef.current.value = value;
        }
    }, [value]);

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        } else if (inputRef.current) {
            inputRef.current.value = value;
        }
    }, [isEditing, value]);

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }, []);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        if (e.target.value !== newValue) {
            e.target.value = newValue;
        }
    }, []);

    const handlePaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedText = e.clipboardData.getData('text');
        if (!/^[0-9]{0,2}$/.test(pastedText)) {
            e.preventDefault();
        }
    }, []);

    return (
        <>
            <input
                ref={inputRef}
                type="text"
                defaultValue={value}
                onBlur={onBlur}
                onKeyUp={handleKeyDown}
                onChange={handleChange}
                onPaste={handlePaste}
                pattern="[0-9]{1,2}"
                maxLength={2}
                aria-label={ariaLabel.input}
                className="w-[2ch] rounded-lg bg-transparent text-center text-3xl font-medium focus:outline-none"
                style={{
                    ...(isEditing ? {} : { display: 'none' }),
                }}
            />
            <div
                className={classNames(
                    'w-[2ch] rounded-lg bg-transparent text-center text-3xl font-medium focus:outline-none',
                    isEditable && 'hover:bg-gray-200'
                )}
                onClick={onClick}
                role="button"
                aria-label={ariaLabel.display}
                style={{
                    ...(isEditing ? { display: 'none' } : {}),
                }}
            >
                {value}
            </div>
        </>
    );
};
