import { WorkspaceJSON } from '@schema-app/workspaces/{workspaceKey}/WorkspaceJSON';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';

export class WorkspaceSetting {
    constructor(
        readonly isPublicSpace: boolean,
        readonly isViewModelURLShareable: boolean
    ) {}

    dump(): WorkspaceJSON['setting'] {
        const { isPublicSpace, isViewModelURLShareable } = this;
        return {
            isPublicSpace,
            isViewModelURLShareable,
        };
    }

    static load(dump: WorkspaceJSON['setting']): WorkspaceSetting {
        const { isPublicSpace, isViewModelURLShareable } = dump;
        return new this(isPublicSpace, isViewModelURLShareable);
    }

    static buildDefault(): WorkspaceSetting {
        return this.load({
            isPublicSpace: false,
            isViewModelURLShareable: false,
        });
    }

    /**
     * 対象ワークスペース・エンティティの読み取り可否を返します。
     *
     * @param role 対象ワークスペースの所属ロール。非所属の場合には null を渡す。
     * @returns 対象のワークスペース・エンティティを読み取り可能な場合に true を返す
     */
    canReadEntity(role: WorkspaceMemberRoleJSON | null): boolean {
        const { isPublicSpace } = this;

        return role !== null || isPublicSpace;
    }
}
