import { injectable } from 'inversify';
import { ModelKey } from '@view-model/domain/key';
import { ApplicationClipboardPayload, ApplicationClipboardPayloadParser } from '@view-model/application/clipboard';

type Unsubscribe = () => void;

type ViewMountedCallbacks = {
    onCopy: () => void;
    onCut: () => void;
    onPaste: (payload: ApplicationClipboardPayload) => Promise<void>;
    onDuplicate: () => void;
};

@injectable()
export class WindowEventManager {
    private readonly _mounted: Record<string, Unsubscribe[]> = {};
    private _onWindowActiveChanged: Unsubscribe | null = null;
    private _onWindowActiveCallbacks: ((windowActive: boolean) => void)[] = [];
    private _onCopyCallbacks: (() => void)[] = [];
    private _onCutCallbacks: (() => void)[] = [];
    private _onPasteCallbacks: ((payload: ApplicationClipboardPayload) => Promise<void>)[] = [];
    private _onDuplicateCallbacks: (() => void)[] = [];
    private _pasting = false;

    constructor() {
        this._onWindowFocus = this._onWindowFocus.bind(this);
        this._onWindowBlur = this._onWindowBlur.bind(this);
        this._onCopy = this._onCopy.bind(this);
        this._onCut = this._onCut.bind(this);
        this._onPaste = this._onPaste.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
    }

    onMounted(onWindowActiveChanged: (windowActive: boolean) => void): void {
        this._onWindowActiveChanged = this.listenOnWindowActive(onWindowActiveChanged);
        window.addEventListener('focus', this._onWindowFocus);
        window.addEventListener('blur', this._onWindowBlur);
        window.document.addEventListener('copy', this._onCopy);
        window.document.addEventListener('cut', this._onCut);
        window.document.addEventListener('paste', this._onPaste);
        window.addEventListener('keydown', this._onKeyPress);
    }

    onWillUnmount(): void {
        window.removeEventListener('focus', this._onWindowFocus);
        window.removeEventListener('blur', this._onWindowBlur);
        window.document.removeEventListener('copy', this._onCopy);
        window.document.removeEventListener('cut', this._onCut);
        window.document.removeEventListener('paste', this._onPaste);
        window.removeEventListener('keypress', this._onKeyPress);
        this._onWindowActiveChanged && this._onWindowActiveChanged();
        this._onCopyCallbacks = [];
        this._onCutCallbacks = [];
        this._onDuplicateCallbacks = [];
    }

    onModelContentsMounted(modelKey: ModelKey, { onCopy, onCut, onDuplicate, onPaste }: ViewMountedCallbacks): void {
        const u = this._mounted[modelKey.toString()] || [];

        u.push(this._listenOnCopy(onCopy));
        u.push(this._listenOnCut(onCut));
        u.push(this.listenOnPaste(onPaste));
        u.push(this._listendOnDuplicate(onDuplicate));

        this._mounted[modelKey.toString()] = u;
    }

    onModelContentsWillUnmount(modelKey: ModelKey): void {
        const u = this._mounted[modelKey.toString()] || [];
        u.forEach((unsubscribe) => unsubscribe());
        delete this._mounted[modelKey.toString()];
    }

    listenOnWindowActive(cb: (windowActive: boolean) => void): Unsubscribe {
        (this._onWindowActiveCallbacks = this._onWindowActiveCallbacks || []).push(cb);
        return () => (this._onWindowActiveCallbacks = this._onWindowActiveCallbacks.filter((i) => i !== cb));
    }

    private _onWindowFocus(): void {
        this._onWindowActiveCallbacks.forEach((cb) => cb(true));
    }

    private _onWindowBlur(): void {
        this._onWindowActiveCallbacks.forEach((cb) => cb(false));
    }

    private _onCopy(e: ClipboardEvent): void {
        if (
            e.target instanceof HTMLInputElement ||
            e.target instanceof HTMLTextAreaElement ||
            (e.target instanceof HTMLElement && e.target.closest('[contenteditable="true"]'))
        ) {
            return;
        }

        // ブラウザDOM上で選択状態になっている文字列があれば、そちらをコピーする
        const selection = document.getSelection();
        if (selection?.toString() !== '') {
            return;
        }

        e.preventDefault();

        this._onCopyCallbacks.forEach((onCopy) => onCopy());
    }

    private _onCut(e: ClipboardEvent): void {
        if (
            e.target instanceof HTMLInputElement ||
            e.target instanceof HTMLTextAreaElement ||
            (e.target instanceof HTMLElement && e.target.closest('[contenteditable="true"]'))
        ) {
            return;
        }

        // ブラウザDOM上で選択状態になっている文字列があれば、そちらを切り取る
        const selection = document.getSelection();
        if (selection?.toString() !== '') {
            return;
        }

        e.preventDefault();

        this._onCutCallbacks.forEach((onCut) => onCut());
    }

    private _listenOnCopy(onCopy: () => void): Unsubscribe {
        (this._onCopyCallbacks = this._onCopyCallbacks || []).push(onCopy);
        return () => (this._onCopyCallbacks = this._onCopyCallbacks.filter((i) => i !== onCopy));
    }

    private _listenOnCut(onCut: () => void): Unsubscribe {
        (this._onCutCallbacks = this._onCutCallbacks || []).push(onCut);
        return () => (this._onCutCallbacks = this._onCutCallbacks.filter((i) => i !== onCut));
    }

    private async _onPaste(e: ClipboardEvent): Promise<void> {
        // ペーストイベントの対象が input, textarea に対する操作の場合には、 data-focus 属性の状態を見て貼り付け処理をスキップする。
        //   - data-focus 属性が付与されていない場合: 貼り付け処理をスキップする
        //   - data-focus 属性が付与されていて 'true' に設定されている場合: 貼り付け処理をスキップする
        //   - data-focus 属性が付与されていて 'true' 以外に設定されている場合: 貼り付け処理を実行する
        //
        // サイドバーのビューモデル検索機能からビューモデルに移動した後に、モデル要素のコピー&ペーストが行えない問題に対処するための処置。
        // 上記のシチュエーションでは、検索テキストボックスのフォーカスが外れているにも関わらず、ペーストイベントの対象が input になっていた。
        // 検索ボックスに対してフォーカス状態に連動する data-focus 属性を付与することで、ペーストイベントの対象を判別する。
        // https://www.notion.so/levii/5f46f11283a64584be7cb5ccb51b8373
        if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
            const dataFocus = e.target.getAttribute('data-focus');
            if (dataFocus === null || dataFocus === 'true') {
                return;
            }
        }

        // 貼り付け処理中の場合には早期 return
        if (this._pasting) {
            return;
        }

        // clipboardData が null の場合には早期 return
        if (!e.clipboardData) {
            return;
        }

        const clipboardData = e.clipboardData.getData('application/json') || e.clipboardData.getData('text/plain');
        const payload =
            ApplicationClipboardPayloadParser.parse(clipboardData) ||
            ApplicationClipboardPayloadParser.parseFromFiles(e.clipboardData.files);

        if (!payload) {
            console.warn('The clipboard data is not valid');
            return;
        }

        e.preventDefault();

        try {
            this._pasting = true;
            await Promise.all(this._onPasteCallbacks.map((onPaste) => onPaste(payload)));
        } finally {
            this._pasting = false;
        }
    }

    _listendOnDuplicate(onDuplicate: () => void): Unsubscribe {
        (this._onDuplicateCallbacks = this._onDuplicateCallbacks || []).push(onDuplicate);
        return () => (this._onDuplicateCallbacks = this._onDuplicateCallbacks.filter((i) => i !== onDuplicate));
    }

    private async _onDuplicate(): Promise<void> {
        this._onDuplicateCallbacks.forEach((onCopyAndPaste) => onCopyAndPaste());
    }

    private _onKeyPress(e: KeyboardEvent): void {
        const withMetaKey = e.ctrlKey || e.metaKey;
        if (withMetaKey && e.key == 'd') {
            e.stopPropagation();
            e.preventDefault();
            this._onDuplicate();
        }
    }

    listenOnPaste(onPaste: (payload: ApplicationClipboardPayload) => Promise<void>): Unsubscribe {
        (this._onPasteCallbacks = this._onPasteCallbacks || []).push(onPaste);
        return () => (this._onPasteCallbacks = this._onPasteCallbacks.filter((i) => i !== onPaste));
    }

    listenOnCopy(onCopy: () => Promise<void>): Unsubscribe {
        (this._onCopyCallbacks = this._onCopyCallbacks || []).push(onCopy);
        return () => (this._onCopyCallbacks = this._onCopyCallbacks.filter((i) => i !== onCopy));
    }
}
