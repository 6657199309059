import { WorkspacePage } from '@user/pages/WorkspacePage';
import '../../App.css';
import { useParams } from 'react-router-dom';
import { NotFoundPage } from '@user/pages/NotFoundPage';
import { ActionLogEventParamsContextProvider } from '@framework/action-log';
import { FullScreenLoading } from '@framework/ui';
import { useWorkspaceEntity } from '@workspace/application/hooks/useWorkspaceEntity';
import { LoginModal } from '@framework/ui/elements/LoginModal';
import { useCurrentUserId } from '@framework/auth';

export const WorkspaceRoutes: React.FC = () => {
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { workspace, accessible, loading } = useWorkspaceEntity(workspaceId);
    const currentUserId = useCurrentUserId();

    if (loading) return <FullScreenLoading />;

    // 権限的に対象ワークスペースにアクセスできない場合には、 Not Found ページを表示する
    if (!accessible) return <NotFoundPage />;

    // ワークスペース・エンティティを取得できていれば、ワークスペース画面を表示する。
    // エンティティを取得できていない場合には、ログインモーダルを表示する。
    return workspace && currentUserId ? (
        <ActionLogEventParamsContextProvider workspaceId={workspace.id} workspaceName={workspace.name.toString()}>
            <WorkspacePage workspace={workspace} currentUserId={currentUserId} />
        </ActionLogEventParamsContextProvider>
    ) : (
        <LoginModal
            isOpen={true}
            onClose={() => void 0}
            title={'Balusを利用する | Use Balus'}
            shouldCloseOnOverlayClick={true}
        />
    );
};
