import { useCallback, useEffect, useRef, useState } from 'react';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { EmptyMessage } from './EmptyMessage';
import { RootFolderContent } from './RootFolderContent';
import { FolderCreateModal } from '@workspace/view-model-folder/ui/FolderCreateModal';
import { ViewModelFolderAdapter, ViewModelFolderAdapterContext } from '@workspace/view-model-folder/adapter';
import { RootFolderContainer } from '@workspace/view-model-folder/domain/RootFolderContainer';
import { Loading } from '@framework/ui/atoms/Loading';
import { ViewModelCreateModal } from '@workspace/view-model-folder/ui/ViewModelCreateModal';
import { MenuButtons } from './MenuButtons';
import { SearchButton, SearchTextBox } from '@workspace/view-model-folder/ui/SearchViewModel';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import {
    useCreateFolderToRoot,
    useCreateViewModelFromClipboardPayloadHandler,
} from '@workspace/view-model-folder/hooks';
import { ViewModelOperation } from '@view-model/domain/view-model';
import { useActionLogSender } from '@framework/action-log';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { WorkspaceMemberRole } from '@workspace/domain/workspace';
import { UserId } from '@schema-common/base';

type Props = {
    workspace: Workspace;
    currentUserId: UserId;
};

type ModalTarget = 'ViewModel' | 'Folder';

export const ViewModelRootFolder: React.FC<Props> = ({ workspace, currentUserId }: Props) => {
    const actionLogSender = useActionLogSender();

    const role = workspace.getMemberRoleOf(currentUserId);
    const showEditMenu = !!(role && [WorkspaceMemberRole.admin, WorkspaceMemberRole.editor].includes(role));

    const [modalTarget, setModalTarget] = useState<ModalTarget | null>(null);
    const [rootFolderContainer, setRootFolderContainer] = useState<RootFolderContainer | null>(null);
    const [query, setQuery] = useState<string>('');
    const [showSearchText, setShowSearchText] = useState<boolean>(false);

    const adapterRef = useRef<ViewModelFolderAdapter | null>(null);

    const createFolderToRoot = useCreateFolderToRoot(workspace.id);

    useEffect(() => {
        const adapter = new ViewModelFolderAdapter(workspace.id);
        adapterRef.current = adapter;

        adapter.onMount((container) => setRootFolderContainer(container));

        return () => adapter.onUnmount();
    }, [workspace.id]);

    const handleClickCreateViewModel = useCallback(() => setModalTarget('ViewModel'), []);
    const handleClickCreateFolder = useCallback(() => setModalTarget('Folder'), []);
    const handleCloseModal = useCallback(() => setModalTarget(null), []);

    const handleCreateViewModelToRoot = useCallback(
        async (name: string) => {
            await ViewModelOperation.createToRoot(workspace.id, name, actionLogSender);
        },
        [actionLogSender, workspace.id]
    );

    const createViewModelFromClipboardPayload = useCreateViewModelFromClipboardPayloadHandler(
        workspace.ownerGroupId,
        workspace.id,
        RootFolderTree.ROOT_ID
    );

    const handlePaste = () => createViewModelFromClipboardPayload();

    const handleSearchButtonClick = () => {
        setShowSearchText(true);
    };

    const handleSearchTextBoxChange = (value: string) => {
        setQuery(value);
    };

    const handleSearchTextBoxClose = () => {
        setQuery('');
        setShowSearchText(false);
    };

    return (
        <div className="mb-5 px-3">
            <div className="mb-3 flex items-center justify-between border-b border-brand">
                <div className="justify-start text-2xl font-bold">
                    <FontAwesomeIcon icon={faProjectDiagram} className="mr-1" />
                    ビューモデル
                </div>

                <div className="flex justify-end p-1">
                    {showEditMenu && !showSearchText && (
                        <MenuButtons
                            onCreateFolder={handleClickCreateFolder}
                            onCreateViewModel={handleClickCreateViewModel}
                            onPaste={handlePaste}
                        />
                    )}
                    {showSearchText ? (
                        <SearchTextBox
                            value={query}
                            onChange={handleSearchTextBoxChange}
                            onClose={handleSearchTextBoxClose}
                            className="mb-1 w-80"
                        />
                    ) : (
                        <SearchButton onClick={handleSearchButtonClick} className="ml-1" />
                    )}
                </div>
            </div>

            <ViewModelFolderAdapterContext.Provider value={adapterRef.current}>
                {rootFolderContainer ? (
                    <>
                        {rootFolderContainer.isEmpty() ? (
                            <EmptyMessage />
                        ) : (
                            <RootFolderContent
                                showEditMenu={showEditMenu}
                                workspace={workspace}
                                rootFolderContainer={rootFolderContainer}
                                searchQuery={query}
                            />
                        )}
                    </>
                ) : (
                    // rootFolderContainer が null の間はローディング表示
                    <Loading />
                )}
            </ViewModelFolderAdapterContext.Provider>

            <ViewModelCreateModal
                isOpen={modalTarget === 'ViewModel'}
                onSubmit={handleCreateViewModelToRoot}
                onClose={handleCloseModal}
            />
            <FolderCreateModal
                isOpen={modalTarget === 'Folder'}
                onSubmit={createFolderToRoot}
                onClose={handleCloseModal}
            />
        </div>
    );
};
